import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Form, Input, Checkbox, Select, Table, Grid } from "antd";
import "dayjs/locale/es";
import dayjs from "dayjs";
import moment from "moment";
import customParseFormat from "dayjs/plugin/customParseFormat";
import axios from "axios";
import {
  BUY_VIEW,
  CURRENCY_ID,
  DAYS_HOURS_VIEW,
  SALE_DETAIL,
  SALE_STATUS_ID,
} from "../constants.js";
import { API_PRICE_LIST } from "../../services/constants/api.js";
import InfoAlert from "../../components/ui/alerts/info/InfoAlert.jsx";
import WarningAlert from "../../components/ui/alerts/warning/WarningAlert.jsx";
import { v4 as uuidv4 } from "uuid";
import { savePayer, startSale } from "../../services/saleService.js";
import StepTitle from "../../components/ui/stepTitle/StepTitle.jsx";
import pdf from '../../assets/files/tyc.pdf'
import { getPriceListURL } from "../../services/utils.js";
import "./tickets.css";

const { useBreakpoint } = Grid;

const { Item } = Form;
const Tickets = () => {
  dayjs.extend(customParseFormat);
  const [date, setDate] = useState();
  const [tickets, setTickets] = useState([]);
  const [ticketsData, setTicketsData] = useState({});
  const [formData, setFormData] = useState({
    fullName: "",
    documentNumber: "",
    email: "",
    confirmEmail: "",
    validData: false
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showFormAlert, setShowFormAlert] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const screens = useBreakpoint();
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const config = {
    headers: { Authorization: "Bearer " + token },
  };

  const setAlertClose = () => {
    setShowAlert(false);
  };

  const setAlertFormClose = () => {
    setShowFormAlert(false);
  };

  const getPrices = async () => {
    return await axios
      .get(getPriceListURL(location.state.dataShop[0].date), config)
      .then((response) => {
        return response.data.data;
      })
      .catch((e) => {
        console.log(e);
        return [];
      });
  };

  const formattedDescription = () => {
    const { hourfrom, hourto } = location.state.dataShop[0];
    const formattedDescription = `De ${moment(hourfrom, "HH:mm:ss").format(
      "HH:mm"
    )} a ${moment(hourto, "HH:mm:ss").format("HH:mm")} `;
    return formattedDescription;
  };

  const formattedDate = (date) => {
    return `${moment(date).format("DD/MM/YYYY")} `;
  };

  const formattedPrice = (price) => {
    return price.toLocaleString("es-AR", {
      style: "currency",
      currency: "ARS",
    });
  };

  const getTicketRecord = (ticketsData) => {
    return {
      ticketsOptions: getTicketsOptions(ticketsData),
      value: "",
      description: "",
      schedule: formattedDescription(),
      price: "",
      discount: "",
    };
  };

  const getTicketsOptions = (ticketsData) => {
    const data = Object.values(ticketsData).map((ticketData) => {
      const { name, amount, price_id } = ticketData;
      return {
        price_id,
        amount,
        value: name,
        label: name,
      };
    });
    return data.sort((a, b) => compareByPropertyValue(a, b))
  };

  const compareByPropertyValue = (a, b) => {

    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  }

  const setDataToTickets = async () => {
    const prices = await getPrices();

    const newTicketsData = prices.reduce((acc, cur) => {
      const key = cur.name;
      return { [key]: cur, ...acc };
    }, {});

    setTicketsData(newTicketsData);
  };

  useEffect(() => {
    setDate(location.state.dataShop[0].date || "");
    setDataToTickets();
  }, []);

  useEffect(() => {
    if (Object.values(ticketsData).length) {
      const arr = Array(
        location.state.quantity || location.state.tickets.length
      )
        .fill()
        .map((ticket) => {
          return {
            ...getTicketRecord(Object.values(ticketsData)),
            key: uuidv4(),
          };
        });
      setTickets(arr);
    }
  }, [ticketsData]);

  const onGobackSubmit = () => {
    location.state.buyByDaysAndHoursView ? navigate(DAYS_HOURS_VIEW) : navigate(BUY_VIEW);
  };

  const startSalesRecord = async () => {
    try {
      const salesDetails = tickets.map((ticket) => ({
        price_id: ticket.priceId,
        salestatus_id: SALE_STATUS_ID,
        amount_total: ticket.amount,
        currency_id: CURRENCY_ID,
      }));
      const { operativecalendarId } = location.state.dataShop[0];
      const data = {
        sales: [
          {
            operativecalendar_id: operativecalendarId,
            sales_count: tickets.length,
            sales_detail: salesDetails,
          },
        ],
      };
      const response = await startSale(data);
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  const cudValidation = (tickets) => {
    const personasConCUD = tickets.some(({ value }) => value.includes("Personas con CUD"));
    const acompañanteCUD = tickets.some(({ value }) => value.includes("Acompañante CUD"));
    return (acompañanteCUD && !personasConCUD) ? false : true;
  }

  const onContinuationSubmit = async () => {
    localStorage.removeItem("savedTickets");
    setLoading(true);
    const ticketChildValidation = tickets.some((ticket) => ticket.ageFrom <= 17)
    const ticketAdultValidation = tickets.some((ticket) => ticket.ageFrom > 17);
    const cudValidationCheck = cudValidation(tickets)

    const isFormComplete = formData.fullName !== "" &&
      formData.documentNumber !== "" &&
      formData.email !== "" &&
      formData.confirmEmail !== "" &&
      formData.validData;
    if (ticketChildValidation && ticketAdultValidation && isFormComplete && termsChecked && cudValidationCheck) {
      const response = await startSalesRecord();
      if (response.data.status >= 200 && response.data.status < 300) {
        const payerInfo = {
          salesOrderId: response.data.data.salesorderId,
          documentNumber: formData.documentNumber,
          name: formData.fullName,
          email: formData.email
        }
        console.log(payerInfo)
        const formResponse = await savePayer(payerInfo)
        if (formResponse.status >= 200 && formResponse.status < 300) {
          navigate(SALE_DETAIL, {
            state: { tickets, date, dataShop: response.data.data, payerInfo: formResponse.data },
          });
          setLoading(false);
        }
      }
    } else if (!isFormComplete || !termsChecked) {
      setShowFormAlert(true);
    } else if (!cudValidationCheck) {
      setShowAlert(true);
      setAlertMessage('No se identificó la entrada para la persona con CUD a la que se acompaña')
    }
    else {
      setShowAlert(true);
      setAlertMessage("Debe seleccionar al menos 1 entrada de adulto y 1 entrada de menor")
    }
    setLoading(false);
  };

  const getNewTicket = (value, key) => {
    return {
      key: key,
      value: value,
      ticketsOptions: getTicketsOptions(ticketsData),
      description: ticketsData[value].description,
      schedule: formattedDescription(),
      price: formattedPrice(ticketsData[value].amount),
      priceId: ticketsData[value].priceId,
      amount: ticketsData[value].amount,
      ageFrom: ticketsData[value].ageFrom,
      ageTo: ticketsData[value].ageTo
    };
  };

  const onChange = (value, key) => {
    const newTickets = tickets.map((ticket) => {
      return ticket.key == key ? getNewTicket(value, key) : ticket;
    });

    setTickets([...newTickets]);
  };

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData({ ...formData, [id]: value });
  };
  const validateName = (rule, value) => {
    const nameRegex = /^[a-zA-Z ]+$/;
    if (value && !nameRegex.test(value)) {
      return Promise.reject("Ingrese solo letras y espacios");
    } else {
      return Promise.resolve();
    }
  };
  const validateDocumentNumber = (rule, value) => {
    const documentNumberRegex = /^[0-9]{1,8}$/;
    if (value && !documentNumberRegex.test(value)) {

      return Promise.reject(() => {
        setFormData({ ...formData, ['validData']: false });
        return "Ingrese solo números y máximo 8 dígitos"
      });
    } else {

      return Promise.resolve(() => {
        setFormData({ ...formData, ['validData']: true });
      });
    }
  };

  const handleConfirmEmailChange = (event) => {
    const { id, value } = event.target;
    setFormData({ ...formData, [id]: value });
  };

  const validateConfirmEmail = (rule, value) => {
    if (value !== formData.email) {
      setFormData({ ...formData, ['validData']: false });
      return Promise.reject("El correo electrónico no coincide");
    } else {
      setFormData({ ...formData, ['validData']: true });
      return Promise.resolve();
    }
  };
  const handleEmailPaste = (event) => {
    event.preventDefault();
  };

  const handleTermsChange = (event) => {
    setTermsChecked(event.target.checked);
  };

  const desktopColumns = [
    {
      title: "Tickets",
      dataIndex: "ticketsOptions",
      // width: 250,
      width: 100,
      render: (ticketsOptions, record) => {
        return (
          <div>
            <Select
              showSearch
              placeholder="Selecciona el tipo"
              optionFilterProp="children"
              onChange={(value) => onChange(value, record.key)}
              value={record.value ? record.value : "selecciona el tipo"}
              size="small"
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              options={ticketsOptions}
              style={{ width: "100%" }}
            />
          </div>
        );
      },
    },
    {
      title: "Descripcion",
      dataIndex: "description",
      width: 150,
      render: (description) => {
        return (
          <p style={{ margin: 0, fontSize: '0.9em' }}>{description}</p>
        );
      },
    },
    {
      title: "Horario",
      dataIndex: "schedule",
      width: 150,
      render: (schedule) => {
        return (
          <p style={{ margin: 0, fontSize: '0.8em' }}>{schedule}</p>
        );
      },
    },
    {
      title: "Precio",
      dataIndex: "price",
      width: 100,
      render: (price) => {
        return (
          <p style={{ margin: 0, fontSize: '0.8em' }}>{price}</p>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      width: 110,
      render: (_, record) => (
        <Button danger onClick={() => handleDeleteRow(record.key)} style={{ padding: 5, fontSize: '1em' }}>
          Quitar
        </Button>
      ),
    },
  ]

  const mobileColumns = [
    {
      title: "Tickets",
      dataIndex: "ticketsOptions",
      width: 100,
      render: (ticketsOptions, record) => {
        return (
          <div>
            <select name="" id=""
              placeholder="Selecciona el tipo"
              defaultValue="Selecciona el tipo"
              onChange={e => onChange(e.target.value, record.key)}
              style={{ width: '100%' }}
            >
              <option value='Selecciona el tipo' disabled hidden></option>
              {ticketsOptions.map((option, index) => <option key={index} value={option.value}>{option.value}</option>)}
            </select>
          </div>
        );
      },
    },
    {
      title: "Descripcion",
      dataIndex: "description",
      width: 80,
      render: (description) => <p style={{ margin: 0, fontSize: '0.9em' }}>{description}</p>
    },
    {
      title: "Horario",
      dataIndex: "schedule",
      width: 60,
      render: (schedule) => <p style={{ margin: 0, fontSize: '0.8em' }}>{schedule}</p>
    },
    {
      title: "Precio",
      dataIndex: "price",
      width: 60,
      render: (price) => <p style={{ margin: 0, fontSize: '0.8em' }}>{price}</p>
    },
    {
      title: "",
      dataIndex: "action",
      width: 65,
      render: (_, record) => (
        <Button danger onClick={() => handleDeleteRow(record.key)} style={{ padding: 5, fontSize: '1em' }}>
          Quitar
        </Button>
      ),
    },
  ];

  const handleDeleteRow = (id) => {
    setTickets(tickets.filter((ticket) => ticket.key !== id));
  };

  return (

    <div className="table">
      <StepTitle
        textTitle={`Selecciona los tipos de tickets- Fecha: ${formattedDate(
          date
        )}`}
      >
        <InfoAlert style={{ fontWeight: 'bold' }} message="Recuerde que: Los menores deben estar acompañados por un Adulto. No se permite el ingreso de Adultos solos al Museo. 
Las entradas no tienen cambio ni devolución. " />
        <br />
      </StepTitle>
      <Table
        columns={screens['xs'] ? mobileColumns : desktopColumns}
        dataSource={tickets}
        rowKey={(record) => record.key}
        locale={{ emptyText: "  " }}
        scroll={{ x: '100%', y: "40vh" }}
        size="small"
        pagination={{ pageSize: 8 }}
      />
      <Form className="userInfoform">
        <h5>Datos del comprador: </h5>
        <Item
          label="Nombre Completo"
          name="fullName"
          value={formData.fullName}
          onChange={handleInputChange}
          rules={[
            {
              required: true,
              message: "Ingrese su nombre completo",
            },
            {
              validator: validateName,
            },
          ]}
        >
          <Input />
        </Item>

        <Item
          label="Nro de Documento"
          name="documentNumber"
          value={formData.documentNumber}
          onChange={handleInputChange}
          rules={[
            {
              required: true,
              message: "Ingrese su número de documento",
            },
            {
              validator: validateDocumentNumber,
            },
          ]}
        >
          <Input />
        </Item>

        <Item
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          rules={[
            {
              type: "email",
              message: "Ingrese un email válido",
            },
            {
              required: true,
              message: "Ingrese su email",
            }
          ]}
        >
          <Input />
        </Item>
        <Item
          label="Confirmar Email"
          name="confirmEmail"
          value={formData.confirmEmail}
          onPaste={handleEmailPaste}
          onChange={handleConfirmEmailChange}

          rules={[
            {
              required: true,
              message: "Confirme su correo electrónico",
            },
            {
              validator: validateConfirmEmail,
            },
          ]}
        >
          <Input aria-autocomplete="off" autoComplete="off" />
        </Item>
        <Form.Item
          name="termsAndConditions"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject("Debe aceptar los Términos y Condiciones"),
            },
          ]}
          style={{ marginBottom: 0 }}
        >
          <Checkbox checked={termsChecked} onChange={handleTermsChange}>
            Acepto los <Link target="_blank" to={pdf}>Términos y Condiciones</Link>
          </Checkbox>
        </Form.Item>
      </Form>
      <div className="button-footer">
        <Button
          type="primary"
          style={{ background: "#FF8165" }}
          onClick={onGobackSubmit}
        >
          Volver
        </Button>

        <Button
          type="primary"
          style={{ background: "#FF8165" }}
          onClick={onContinuationSubmit}
          // loading={loading}
          disabled={loading}
        >
          {loading ? '...Cargando' : 'Continuar'}
        </Button>
      </div>

      {showAlert && (
        <div style={{ marginTop: "1rem" }}>
          <WarningAlert
            message={
              alertMessage
            }
            alertCloseAction={setAlertClose}
          />
        </div>
      )}
      {showFormAlert && (
        <div style={{ marginTop: "1rem" }}>
          <WarningAlert
            message={
              "Debe Completar correctamente los campos requeridos en el formulario"
            }
            alertCloseAction={setAlertFormClose}
          />
        </div>
      )}
    </div>

  );
};

export default Tickets;
